import { securedWrap } from '@mop/shared/utils/securedWrap';

export default function useMopCookieOverlayClient() {
  const overlay = useMopOverlay();

  async function openCookieOverlay(showSettings = true, callbackFn?: Function) {
    await overlay.open({
      type: 'centered',
      componentName: 'MopCookiesOverlay',
      urlHash: OVERLAYS.COOKIE.URL_HASH,
      overrideParams: {
        showClose: false,
        enableShadowClose: false,
        forceRender: true,
      },
      onClose: () => {
        callbackFn && callbackFn();
      },
      props: {
        showSettings,
      },
    });
  }

  async function closeCookieOverlay() {
    await overlay.closeAll();
  }

  return securedWrap({
    openCookieOverlay,
    closeCookieOverlay,
  });
}
