// On GA4 migration: move all the logic from useMopTrackingClient.ts, useMopGtm.ts, gtmUtils.ts and gtm.ts to here

import { isClient, loadScript } from '@mop/shared/utils/util';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { sessionStorageGet, sessionStorageRemove, sessionStorageSet } from '@mop/shared/utils/sessionStorage';
import type {
  Gtm2EcommerceParams,
  Gtm2EcommerceParamsProduct,
  Gtm2EcommerceItem,
  GtmProductListType,
  Gtm2EcommerceBaseEvent,
  Gtm2EcommerceEvent,
  Gtm2EcommerceEventType,
  Gtm2PageViewParams,
  Gtm2PromotionParams,
  Gtm2PromotionEvent,
  Gtm2Params,
  Gtm2Event,
} from '@/types/gtm';
import type { ProductModel } from '@/types/product';

const MAX_DATALAYER_BULK_SIZE = 25;
const DATA_NOT_AVAILABLE_VALUE = 'na';

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp.$nuxt;
  const { $mopI18n, $abTestServerSide, $mopConfig } = app;
  if (!$mopI18n) {
    return;
  }
  const config = useRuntimeConfig();
  const { isCookieConsentRequired } = useMopPrivacy();
  const { reportPageView } = useMopTrackingClient();
  let isGtmScriptAdded = false;
  let datalayerBulk: Gtm2EcommerceBaseEvent[] = [];

  if (isEnabled()) {
    initGtm();
  }

  function initGtm() {
    window.dataLayer = window.dataLayer || [];
    window.addEventListener('beforeunload', commitDatalayer);
    window.addEventListener('blur', commitDatalayer);

    initVueRouterGuard();
  }

  function isEnabled(): boolean {
    return (
      isClient &&
      config.public.GTM_ENABLE &&
      config.public.GTM_CONTAINER_ID !== '' &&
      !useRoute().query.disablegtm &&
      app.$storyblokLivePreview.isEnabled === false
    );
  }

  function initVueRouterGuard() {
    if (!isEnabled()) {
      return;
    }
    const router = useRouter();
    router.beforeEach((to) => {
      if (!to.fullPath) {
        return;
      }
      commitDatalayer();
    });
  }

  function pushEventToDataLayer(event: Gtm2Event) {
    if (!isEnabled()) {
      return;
    }
    window.dataLayer.push(event);
  }

  function pushEcommerceEventToDataLayer(event: Gtm2EcommerceBaseEvent, enableBundling = false) {
    if (!isEnabled()) {
      return;
    }
    if (!enableBundling) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push(event);
      return;
    }

    datalayerBulk.push(event);

    if (enableBundling && datalayerBulk.length >= MAX_DATALAYER_BULK_SIZE) {
      commitDatalayer();
    }
  }

  function commitDatalayer() {
    if (!isEnabled()) {
      return;
    }

    groupEvents();
    datalayerBulk.forEach((event) => {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push(event);
    });
    datalayerBulk = [];
  }

  function groupEvents() {
    if (!isEnabled()) {
      return;
    }
    const allEvents: Gtm2EcommerceBaseEvent[] = [];
    const groupableEvents: Gtm2EcommerceEventType[] = ['view_item_list'];
    const groupedEventItems: { [key: string]: Gtm2EcommerceBaseEvent } = {};
    datalayerBulk.forEach((trackingEvent: Gtm2EcommerceBaseEvent) => {
      const event = trackingEvent.event;
      if (groupableEvents.includes(event)) {
        if (!groupedEventItems[event]) {
          groupedEventItems[event] = trackingEvent;
        } else if (trackingEvent.ecommerce.items?.length) {
          const newItem = trackingEvent.ecommerce.items.shift() as Gtm2EcommerceItem;
          groupedEventItems[event].ecommerce.items.push(newItem);
          groupedEventItems[event].ecommerce.value += getItemValue(newItem);
        }
        return;
      }
      allEvents.push(trackingEvent);
    });

    Object.keys(groupedEventItems).forEach((groupedEventItemKey) => {
      allEvents.push(groupedEventItems[groupedEventItemKey]);
    });

    datalayerBulk = allEvents;
  }

  function getCategoryPath(productTrackingParams: Gtm2EcommerceParamsProduct): string {
    let categoryPath = '';
    const product: ProductModel = productTrackingParams.product;

    if (!productTrackingParams.category) {
      categoryPath = [product.getBreadcrumbPath().split('-').join('/'), product.getMopId()].join('/');
    } else {
      const category: string = productTrackingParams.category;
      categoryPath = category.includes(product.getMopId()) ? category : [category, product.getMopId()].join('/');
    }
    return categoryPath.replace(/^\//, '');
  }

  function getGtmListTypeKey(product: ProductModel) {
    return `gtm_list_type_${product.getMopId()}`;
  }

  function getEcommerceItems(
    paramsProducts: Gtm2EcommerceParamsProduct[],
    event: Gtm2EcommerceEventType,
  ): Gtm2EcommerceItem[] {
    const isPopularityFlagEnabledForSaleProducts = $mopConfig.isPopularityFlagEnabledForSaleProducts();

    return paramsProducts.map((paramsProduct, index) => {
      const { product, variant } = paramsProduct;
      const priceInformation = variant ? variant.getPrice() : product.getPrice();

      const listTypeKey = getGtmListTypeKey(product);
      if (event === 'select_item' && paramsProduct.list) {
        sessionStorageSet(listTypeKey, paramsProduct.list);
      } else {
        paramsProduct.list = (sessionStorageGet(listTypeKey) as GtmProductListType) || paramsProduct.list;
      }

      const price =
        paramsProduct.price || (variant ? variant.getPrice().salesPriceNet : priceInformation.salesPriceNet);

      return {
        // GA4 default attributes
        item_id: product.getMopMasterId(),
        item_name: product.getName(),
        index,
        item_brand: product.getSimpleBrandName() || DATA_NOT_AVAILABLE_VALUE,
        item_category: getCategoryPath(paramsProduct) || DATA_NOT_AVAILABLE_VALUE,
        item_list_name: paramsProduct.list || DATA_NOT_AVAILABLE_VALUE,
        item_variant: product.getColorId() || DATA_NOT_AVAILABLE_VALUE,
        price: price || 0,
        quantity: paramsProduct.quantity || 1,

        // MOP Custom attributes
        product_id: product.getMopId(),
        product_variation_id: variant?.getEan() || DATA_NOT_AVAILABLE_VALUE,
        product_category: product.getPrimaryCategoryId() || DATA_NOT_AVAILABLE_VALUE,
        season: product.getSeason() || DATA_NOT_AVAILABLE_VALUE,
        gender: product.getGender() || DATA_NOT_AVAILABLE_VALUE,
        size: variant?.getSize() || DATA_NOT_AVAILABLE_VALUE,
        sustainablematerial: product.getSustainabilityId() || DATA_NOT_AVAILABLE_VALUE,
        sale: priceInformation.salesPrice !== priceInformation.basePrice,
        salepercent: priceInformation.discountPercentage ?? 0,
        saletotal: priceInformation.basePrice * ((priceInformation.discountPercentage ?? 0) / 100),
        correctionGlasses: product.isCorrectionGlasses(),
        pdpVideo: Boolean(product.getVimeoId()),
        shipFromStoreProduct: variant?.isShipFromStore() ?? DATA_NOT_AVAILABLE_VALUE,
        virtualTryOnPotential: product.isVirtualTryOnEnabled(),
        virtualTryOnDone: product.isProductTriedWithVirtualTryOn(),
        popularityFlag:
          product.getPopularityFlag(isPopularityFlagEnabledForSaleProducts)?.type || DATA_NOT_AVAILABLE_VALUE,
      } as Gtm2EcommerceItem;
    });
  }

  function trackPromotion(params: Gtm2PromotionParams) {
    const gtmEcommerceEvent: Gtm2PromotionEvent = {
      event: params.event,
      ecommerce: {
        creative_name: params.data.creativeName,
        creative_slot: params.data.creativeSlot,
        promotion_id: params.data.promotionId,
        promotion_name: params.data.promotionName,
        ...params.data.customData,
      },
    };

    pushEcommerceEventToDataLayer(gtmEcommerceEvent, params.enableBundling);
  }

  function getItemValue(ecommerceItem: Gtm2EcommerceItem) {
    return Number((ecommerceItem.price * (ecommerceItem.quantity || 1)).toFixed(2));
  }

  function trackEcommerce(params: Gtm2EcommerceParams) {
    const paramsProducts = params.data.products;
    const ecommerceItems = getEcommerceItems(paramsProducts, params.event);
    if (params.event === 'purchase') {
      paramsProducts.forEach((paramsProduct) => {
        sessionStorageRemove(getGtmListTypeKey(paramsProduct.product));
      });
    }
    const currency = params.data.currency || paramsProducts[0]?.product?.getPrice()?.currency;
    const value =
      params.data.value ??
      ecommerceItems.reduce((sum: number, ecommerceItem: Gtm2EcommerceItem) => {
        sum += getItemValue(ecommerceItem);
        return sum;
      }, 0);

    const gtmEcommerceEvent: Gtm2EcommerceEvent = {
      event: params.event,
      ecommerce: {
        currency,
        value,
        items: ecommerceItems,
        ...params.data.customData,
      },
    };

    pushEcommerceEventToDataLayer(gtmEcommerceEvent, params.enableBundling);
  }

  function initTracking() {
    if (isGtmScriptAdded || !isEnabled() || isCookieConsentRequired()) {
      return;
    }
    isGtmScriptAdded = true;
    loadScript({
      source: `https://www.googletagmanager.com/gtm.js?id=${config.public.GTM_CONTAINER_ID}`,
      callback: () => {
        window.dataLayer.push({
          event: 'gtm.js',
          'gtm.start': new Date().getTime(),
          'gtm.uniqueEventId': 0,
        });
      },
    });
  }

  function trackPageView(pageViewEvent: Gtm2PageViewParams) {
    // @todo: move reportPageView code to here after UA drop
    const customData = pageViewEvent.customData || {};
    reportPageView({
      gtm: {
        data: {
          pageType: pageViewEvent.pageType,
          pageCategoryId: pageViewEvent.pageCategoryId,
          ...customData,
        },
      },
    });
  }

  function trackEvent(params: Gtm2Params) {
    const gtmEvent: Gtm2Event = {
      event: params.event,
      ...params.data,
    };

    pushEventToDataLayer(gtmEvent);
  }

  function trackViewAbTest(position: string = '') {
    trackEvent({
      event: 'view_mop_abtest',
      data: {
        parameter1: $abTestServerSide.abTestName,
        parameter2: $abTestServerSide.abTestVariant,
        parameter3: position,
      },
    });
  }

  nuxtApp.provide(
    'gtm2',
    securedWrap({
      initTracking,
      trackEcommerce,
      trackPageView,
      trackPromotion,
      trackEvent,
      trackViewAbTest,
    }),
  );
});
