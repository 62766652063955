import type { ViewportSize } from '@/types/breakpoint';
import type { CmsVisibility } from '@/types/cms';

// Add more options, when neded
export const AB_TEST = {
  QUERY_NAME: 'abtestcookievalue',
  NEWSLETTER: {
    NAME: 'AB-Newsletter-Registration-2024-03',
    CONTROL_VALUE: 'AB-Newsletter-Registration-2024-03|Control',
    VARIANT_VALUE: 'AB-Newsletter-Registration-2024-03|Variant',
  },
  PLP_PRODUCT_TILES: {
    NAME: 'AB-PLP-Producttiles-2024',
    CONTROL_VALUE: 'AB-PLP-Producttiles-2024|Control',
    VARIANT_VALUE: 'AB-PLP-Producttiles-2024|Variant',
  },
} as const;

export default {
  GLOBAL_CACHE_VALUE_KEY: 'global-components',
  COMMON: {
    MEN: 'm',
    WOMEN: 'f',
    SALUTATION_MEN: 'Mr',
    SALUTATION_WOMEN: 'Ms',
  },
  BREAKPOINT: {
    TINY: 'xs' as ViewportSize,
    SMALL: 's' as ViewportSize,
    MEDIUM: 'm' as ViewportSize,
    LARGE: 'l' as ViewportSize,
    EXTRA_LARGE: 'xl' as ViewportSize,
    EXTRA_EXTRA_LARGE: 'xxl' as ViewportSize,
    INFINITE: 'infinite' as ViewportSize,
  },
  TRANSITION_TIME: {
    ENTER: 1000,
    LEAVE: 200,
  },
  PDP_SLUG_PREFIX: 'p/',
  INVENTORY_CALL_THRESHOLD: 12,
  BODY_CLASS_NO_SCROLL: 'body--noscroll',
  ARVATO_SUPPLIER_ID: 100,
  BRAND_NAME_DEFAULT: 'MARC O’POLO',
  BRAND_NAME_DEFAULT_SHORT: 'MO’P',
  COOKIE: {
    // storage keys should not make sense to the outside world whoever is checking
    BASKET: 'csmopb', // cookie storage mop basket
    WISHLIST: 'csmopw', // cookie storage mop wishlist
    TOP_LEVEL_CATEGORY: 'csmtc', // cookie storage mop top category
    ACCESS_TOKEN_LOCALE: 'csmopatl', // cookie storage checkout authentication localized token to get customer details
    CUSTOMER_GROUP: 'csmcg', // cookie storage customer group
    IS_AUTHENTICATED: 'csmia', // cookie storage to determine authentication
    PRIVACY: 'privacy',
    PRIVACY_OPT_OUT_PERFORMANCE: 'privacy-opt-out-performance',
    DEBUG: 'debug',
    LANGUAGE: 'lang',
    RECOMMENDATION_USER_ID: 'csmopr', // cookie storage mop recommendation
    RECOMMENDATION_USER_SESSION_ID: 'csmoprs', // cookie storage mop recommendation session
    ACCEPTED_COOKIE_CONSENT_VERSION: 'accepted-cookie-consent-version',
    GLOBAL_E_DATA: 'GlobalE_Data',
    AB_TEST: 'csmab', // cookie storage for current ab test
    HAS_SEEN_CATEGORY_NAVIGATION: 'csmhscn', // cookie storage mop for has-seen-category-navigation
    AFFILIATE_TRACKING: 'csmopat', // cookie storage affiliate tracking
  },
  LOCAL_STORAGE: {
    COUNTRY_LOCALISATION: 'lsmopcl', // local storage holding data about customer's geo country
    COUNTRY_SELECTED: 'lsmopcs', // local storage holding previously selected country value
    PREVIEW_CUSTOMER_GROUP: 'lsmoppcg', // local storage holding customer group for storyblok live preview
    ANONYMOUS_RESERVATION: 'lsmopar', // local storage mop anonymous reservation
    RESERVATION: 'lsmopr', // local storage mop reservation
    SUPER_BANNER: 'lsmopsb', // local storage mop super banner
    DISABLED_GIVEAWAY: 'lsmopg', // local storage removed giveaway
    AB_TASTY: 'lsmopabt', // local storage customer id for ab tasty
    EPOQ_SESSION_ID: '7cb54b_session_id',
    VIRTUAL_TRY_ON: 'lsvto', // local storage holding products that customer used virtual try on service
    VIRTUAL_TRY_ON_ACCEPTED: 'lsvtoa', // local storage holding products that customer accepted vto
    ADD_TO_WALLET_TOAST_LAST_SHOWN: 'lsmopatwtls',
    SAIZ_SESSION_ID: 'saiz_session_id',
    HIDE_GUEST_BUYER_ACCOUNT_BADGE: 'lsmophgbab', // local storage for hiding guest buyer account badge
    GUEST_BUYER_HOME_POPUP_SHOWN: 'lsmopgbhps', // local storage for guest buyer home popup shown
    WHATS_APP_CLOSED: 'lsmopwac', // local storage holding flag for whats app popup being closed
  },
  SESSION_STORAGE: {
    ORDER_FETCH_HASH: 'ssmopofh', // session storage holding customer security hash for order retrieve
    CUSTOMER_JUST_LOGGED_IN: 'ssmopcjli', // session storage customer just logged in
    REPORT_LOGIN: 'ssmoprl', // session storage report login for DY
    CHEERING_MESSAGES_SEEN: 'ssmopcms', // session storage for seen cheering messages,
    CLOSED_MEMBERRY_INFOBOX: 'ssmopcmi', // session storage for closed memberry infobox
    LOYALTY_STORE_ID: 'ssmoplsi',
  },
  SCROLL_DIRECTION_UP: 'up' as string,
  SCROLL_DIRECTION_DOWN: 'down' as string,
  KEY_CODES: {
    ESCAPE: 27,
  },
  ERROR_CODE: {
    NOT_FOUND: 404,
    WISHLIST: {
      ITEM_NOT_ADDED: 1000,
    },
    CART: {
      ITEM_NOT_ADDED: 1100,
    },
    NEWSLETTER: {
      ALREADY_EXISTS: 409,
    },
  },
  ERROR_MESSAGE: {
    NEWSLETTER: {
      // https://marc-o-polo.atlassian.net/wiki/spaces/DEV/pages/1362003760/Newsletter+API
      OPT_IN_ALREADY_SET: 'optin already set',
      RAFFLE_ADDED: 'raffle added',
      RAFFLE_ALREADY_SET: 'raffle already set',
      ADVENTCALENDERTHEME_ADDED: 'adventcalendartheme added',
      ADVENTCALENDERTHEME_ALREADY_SET: 'adventcalendartheme already set',
    },
  },
  CHECKOUT: {
    REGISTER_SUFFIX: '/embedded/register?auth=',
    LOGIN_SUFFIX: '/embedded/account/login?auth=',
    LOGOUT_SUFFIX: '/embedded/logout?auth=',
    PASSWORD_RESET_SUFFIX: '/embedded/account/password-reset',
  },
  QUERY_PARAMETERS: {
    PRICES: 'prices',
    SEARCH_QUERY: 'q',
    SORT_QUERY: 'sort',
    PAGE_QUERY: 'page',
  },
  LEGACY_PRODUCT_ID_KEY: 'styleKey',
  META: {
    INDEXABLE: 'index, follow',
    NOT_INDEXABLE: 'noindex, follow',
  },
  REDIRECT_CODES: {
    PERMANENT_REDIRECT: 301,
    FOUND_REDIRECT: 302,
    TEMPORARY_REDIRECT: 307,
  },
  REDIRECT_PATHS: {
    PRODUCT_REDIRECT: 'to-pid',
    CATEGORY_REDIRECT: 'to-cgid',
    PAGE_REDIRECT: 'to-page',
    EXTERNAL_REDIRECT: 'to-external',
  },
  CATEGORY_SLUG: {
    SALE: 'sale',
  },
  VIRTUAL_TOP_CATEGORIES: ['women-junior', 'women-living'] as string[],
  RUNTIME_ENVIRONMENT: {
    PRODUCTION: 'production',
    DEVELOPMENT: 'development',
  },
  ORDER: {
    MOP_WAREHOUSE_GLN: '1967000000101' as string,
    ARVATO_WAREHOUSE_GLN: '4399902067942' as string,
    FALLBACK_IMAGE: 'images/3d44efebb367bb5a607cdb99339c4021.jpg',
  },
  CUSTOMER: {
    DR: 'doctor',
    PROF: 'professor',
    PROF_DR: 'professor-doctor',
  },
  CUSTOMER_GROUPS: {
    GUEST: 'Guest',
    MEMBER: 'Member',
    MEMBER_NO_MAIL_OPT_IN: 'MemberNoMailOptIn',
    MEMBER_NO_POST_OPT_IN: 'MemberNoPostOptIn',
    MEMBER_WITH_POST_OPT_IN: 'MemberWithPostOptIn',
    MEMBER_BONUSPOINT_RESET: 'MemberBonuspointsReset',
    MEMBER_NO_FAVORITE_STORE: 'MemberNoFavoriteStore',
    MEMBER_WITH_BONUS_MIGRATION: 'MemberWithBonusMigration',
    MEMBER_NO_BONUS_MIGRATION: 'MemberNoBonusMigration',
    MEMBER_WITH_FREE_SHIPPING: 'MemberWithFreeShipping',
  },
  PROMOTIONS: {
    SALE_PREFERENCES: 'SalePreferences',
    FREE_SHIPPING_PREFERENCES: 'FreeShippingPreferences',
    GIVEAWAY_PREFERENCES: 'GiveawayPreferences',
    PROMOTION_PREFERENCES: 'PromotionPreferences',
  },
  STORYBLOK: {
    VISIBILITY_ALL: 'All' as CmsVisibility,
    VISIBILITY_HIDDEN: 'Hidden' as CmsVisibility,
    VISIBILITY_NONE: 'None' as CmsVisibility,
    STORY_PATHS: {
      COUNTRY_SELECTOR: 'shop/country-selector',
      COOKIE_BANNER: 'shop/cake-menu',
      SHOPPING_APPOINTMENT: 'shop/shopping-appointment',
      SHOP_ERROR: 'shop/error',
      CART: 'shop/cart',
      WISHLIST: 'shop/wishlist',
      SEARCH_NO_RESULT: 'shop/search-no-result',
      FOR_MEMBERS: 'shop/formembers',
      NEWSLETTER_RAFFLE: 'shop/newsletter/raffle',
      PDP_BRANDS: 'shop/pdp/pdp-brands',
      PDP_SUSTAINABILITY: 'shop/pdp/pdp-sustainability',
      STORE_RESERVATION: 'shop/store-reservation',
      ORDER_CONFIRMATION: 'shop/thank-you',
      ACCOUNT: 'shop/account',
      ACCOUNT_CONTACT_SETTINGS: 'shop/account/contact-settings',
      ACCOUNT_MEMBERS: 'shop/account/members',
      ACCOUNT_MEMBERS_NO_BONUS: 'shop/account/non-bonus-overlay',
      ACCOUNT_PAY_INVOICE: 'shop/account/pay-invoice-overlay',
      ADD_TO_WALLET_OVERLAY: 'shop/account/add-to-wallet-overlay',
      WALLET_LANDING: 'shop/wallet-landing',
      ADD_TO_WALLET_TOAST: 'shop/account/add-to-wallet-toast',
      SIZE_TABLES: 'shop/pdp/size-tables',
      CONTACT: 'shop/contact',
      CHECKOUT_GLOBAL_E: 'shop/checkout/global-e',
      SIZE_ADVICE_OVERLAY: 'shop/size-advice-overlay',
      RETURN_PORTAL: 'shop/return-portal',
    },
    GLOBAL_STORY_NAMES: {
      FOOTER: 'Footer',
      FLYOUT_SERVICE: 'Flyout Service',
      SHOP_PREFERENCES: 'Shop Preferences',
      SHOP_PREFERENCES_AB_TEST: 'Shop Preferences - AB Test',
      SHOP_PREFERENCES_CATEGORIES: 'Shop Preferences - Categories',
      SHOP_PREFERENCES_PRODUCTS: 'Shop Preferences - Products',
      SHOP_PREFERENCES_POPULARITY_FLAGS: 'Shop Preferences - Popularity Flags',
      SUPER_BANNER: 'Super Banner',
      SALE_PREFERENCES: 'Sale Preferences',
      FREE_SHIPPING_PREFERENCES: 'Free Shipping Preferences',
      GIVEAWAY_PREFERENCES: 'Giveaway Preferences',
      PROMOTION_PREFERENCES: 'Promotion Preferences',
      MAINTENANCE: 'Maintenance',
      GUEST_BUYER_PAGE: 'Guest Buyer Page',
    },
  },
  STORE_FINDER: {
    LIST: 'list',
    MAP: 'map',
    STORE_CLOSED: 'closed',
  },
  NEWSLETTER_SOURCE: {
    ACCOUNT: 'MyAccount',
    FOOTER: 'Footer',
    RAFFLE: 'Raffle',
    ADVENT: 'Adventcalendar',
    PREFERENCECENTER: 'PreferenceCenter',
    BACK_IN_STOCK: 'BackInStock',
  },
  NEWSLETTER_CATEGORY: {
    SOI: 'newsletter_soi',
    DOI: 'newsletter_doi',
  },
  NEWSLETTER_TYPE: {
    RAFFLE: 'raffle',
    ADVENT: 'advent',
  },
  ROUTE: {
    NAMES: {
      CATEGORY_PAGE: 'category-page',
    },
  },
  NEWSLETTER_GLOBAL_E_DISABLED_THEME_IDS: ['themeLoyalty'] as string[],
  DISALLOWED_ALTERNATE_SLUGS: [
    'ge-ge',
    'en-dk',
    'en-gr',
    'en-pt',
    'en-no',
    'en-is',
    'en-hr',
    'en-rs',
    'en-tr',
    'en-ae',
    'en-il',
    'en-qa',
    'en-in',
    'en-sg',
    'en-jp',
    'en-hk',
    'en-fi',
    'en-lu',
    'en-lv',
    'en-lt',
    'en-sk',
    'en-si',
    'en-ro',
    'en-hu',
    'en-ee',
    'en-it',
    'en-es',
  ] as string[],
  FORCED_ALTERNATE_SLUGS: ['en-us', 'en-ca', 'en-nz'],
  WALLET: {
    APPLE: 'Apple Wallet',
    GOOGLE: 'Google Wallet',
  },
} as const;

export const OVERLAYS: any = {
  COOKIE: {
    URL_HASH: 'overlay-cookie',
  },
  COUNTRY: {
    URL_HASH: 'overlay-country',
  },
  SUPER_BANNER: {
    URL_HASH: 'overlay-superbanner',
  },
} as const;

export const WEEKDAYS: string[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const;

export const URLS: { [key: string]: string } = {
  URL_PREFIX: '/([a-z]{2}-[a-z]{2})',
  LOGOUT: 'logout',
  REDIRECT_NOT_FOUND: 'redirect-not-found',
  ACCOUNT: 'account',
  GIFT_CARD: 'gift-card',
  ACCOUNT_REGISTER: 'account/register',
  ACCOUNT_ORDERS: 'account/orders',
  ACCOUNT_ORDER: 'account/order/',
  ACCOUNT_PROFILE: 'account/profile',
  ACCOUNT_CONTACT_SETTINGS: 'account/profile#contact',
  ACCOUNT_MEMBERS: 'account/members',
  ACCOUNT_ABOUT_BONUSPOINT: 'inspiration/memberry-bonuspoints',
  AUTH_REDIRECT: 'auth/auth-redirect',
  PASSWORD_RESET: 'password/reset',
  CONTACT: 'contact',
  SHIPMENT_TRACKING: 'shipment-tracking',
  SEARCH: 'search',
  CHECKOUT_SUCCESS: 'checkout/success',
  STORE_FINDER: 'storefinder',
  SHOPPING_APPPOINTMENT: 'shopping-appointment',
  NEWSLETTER_INFORMATION: 'static/newsletter-information',
  NEWSLETTER_REGISTRATION: 'newsletter/registration',
  NEWSLETTER_CONFIRMATION: 'newsletter/confirmation',
  PREFERENCE_CENTER: 'newsletter/preference-center',
  CART: 'cart',
  WISHLIST: 'wishlist',
  CHECKOUT: 'checkout',
  CHECKOUT_GLOBAL_E: 'checkout/global-e',
  STORE_RESERVATION: 'store-reservation',
  MEMBERS: 'formembers',
  RETURN_PORTAL: 'return-portal',
  IMPRINT: 'static/imprint',
  BROWSER_NOT_SUPPORTED: 'browser-not-supported',
  PRIVACY: 'static/privacy',
  TERMS_AND_CONDITIONS: 'static/terms-and-conditions',
  FAQ: 'static/faq',
  FAQ_MEMBER: 'static/faq#member',
  FAQ_PAYMENT: 'static/faq#payment',
  FAQ_SHIPPING: 'static/faq#shipping',
  FAQ_SHIPPING_DELIVERY_TIMES: 'static/faq#shipping-3',
  WALLET_LANDING: 'wallet',
} as const;

export const EXTERNAL_LIBS: { [key: string]: string } = {
  APP_INSIGHTS: 'https://js.monitor.azure.com/scripts/b/ai.2.min.js',
  AB_TASTY: 'https://try.abtasty.com/{ID}.js',
  EPOQ_SNIPPET: 'https://cdn.epoq.de/flow/{TENANT_ID}.js',
} as const;

// more about image indexes: https://marc-o-polo.atlassian.net/wiki/spaces/DEV/pages/2467791055/Images+CP
export const PRODUCT_IMAGE_INDEX: { [key: string]: number } = {
  STANDARD: 1,
  BACK: 2,
  FULL: 3,
  DETAIL: 4,
  SIDE: 5,
  BUST: 7,
  SHOP_THE_LOOK: 8,
} as const;

export const PRODUCT_IMAGE_DIVISION: { [key: string]: number } = {
  STANDARD: 2,
  CASUAL: 4,
  LICENSE: 5,
} as const;

export const MOP_CATEGORY_IDS: { [key: string]: string } = {
  DENIM: 'mopd',
} as const;
